import { flowRight } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import classNames from 'classnames';
import { EXPERIMENT_MOBILE_MENU_COLOR_MATCH_DESKTOP } from '@wix/communities-blog-experiments';
import { NAVIGATION_TEXT_COLOR_PATH } from '@wix/communities-blog-client-common/dist/src/constants/wix-params';
import HeaderButtons from '../header-buttons';
import HeaderTitle from '../header-title';
import withButtonColor from '../../hoc/with-button-color';
import withFontClassName from '../../hoc/with-font-class-name';
import withBlogMenuSettings from '../../hoc/with-blog-menu-settings';
import withExperiment from '../../hoc/with-experiment';
import withSettingsColor from '../../hoc/with-settings-color';
import styles from './header.scss';

export class Header extends Component {
  render() {
    const {
      left,
      right,
      menu,
      contentFontClassName,
      buttonColor,
      hideNavBar,
      showBlogMenu,
      showCategoryLabels,
      isMobileMenuColorMatchDesktopExperimentEnabled,
      navigationDividerColor,
    } = this.props;

    const containerClassName = isMobileMenuColorMatchDesktopExperimentEnabled
      ? classNames(
          styles.headerContainer,
          'blog-navigation-container-color',
          'blog-header-background-color',
          'blog-navigation-container-font',
        )
      : classNames(styles.headerContainer, 'blog-button-primary-text-color', contentFontClassName);

    const showMenu = menu && showBlogMenu && showCategoryLabels;
    const wrapperClassName = isMobileMenuColorMatchDesktopExperimentEnabled
      ? styles.header
      : classNames(styles.header, 'blog-default-button-background-color');

    const wrapperStyle = isMobileMenuColorMatchDesktopExperimentEnabled
      ? { borderBottom: `1px solid ${navigationDividerColor}` }
      : { backgroundColor: buttonColor };

    return (
      <div className={containerClassName}>
        {!hideNavBar && showBlogMenu && (
          <div className={wrapperClassName} style={wrapperStyle}>
            {left}
            {right}
          </div>
        )}
        {showMenu && <div style={wrapperStyle}>{menu}</div>}
      </div>
    );
  }
}

Header.propTypes = {
  contentFontClassName: PropTypes.string,
  buttonColor: PropTypes.string,
  left: PropTypes.node,
  right: PropTypes.node,
  menu: PropTypes.node,
  hideNavBar: PropTypes.bool,
  showBlogMenu: PropTypes.bool,
  showCategoryLabels: PropTypes.bool,
  isMobileMenuColorMatchDesktopExperimentEnabled: PropTypes.bool,
  navigationDividerColor: PropTypes.string,
};

Header.defaultProps = {
  right: <HeaderButtons />,
  left: (
    <div className={styles.left}>
      <span className={styles.title}>
        <HeaderTitle />
      </span>
    </div>
  ),
};

export default flowRight(
  withButtonColor,
  withFontClassName,
  withBlogMenuSettings,
  withSettingsColor(NAVIGATION_TEXT_COLOR_PATH, 'navigationDividerColor', 'color-5', 0.2, '#000000'),
  withExperiment({
    isMobileMenuColorMatchDesktopExperimentEnabled: EXPERIMENT_MOBILE_MENU_COLOR_MATCH_DESKTOP,
  }),
)(Header);
