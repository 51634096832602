import React, { Component } from 'react';
// import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
// import throttle from 'raf-throttle';
// import scrollParent from '../../../common/services/scroll-parent';

// const ALWAYS_VISIBLE_HEIGHT = 500;

export default class CtaToFab extends Component {
  position;
  positionHidden;

  state = {
    isVisible: false,
  };

  // onScroll = throttle(() => {
  //   const position = scrollParent().scrollTop;
  //   const isButtonHidden = this.positionHidden < position + this.props.scrollOffset;
  //   const isInAlwaysVisibleZone = this.positionHidden + ALWAYS_VISIBLE_HEIGHT > position;
  //   const isScrollUp = this.position > position;
  //   this.setState({ isVisible: isButtonHidden && (isInAlwaysVisibleZone || isScrollUp) });
  //   this.position = position;
  // });

  // componentDidMount() {
  //   const el = ReactDOM.findDOMNode(this);
  //   const box = el.getBoundingClientRect();
  //   this.position = window.pageYOffset;
  //   this.positionHidden = this.position + box.bottom;
  //   this.startListening();
  // }

  // componentWillUnmount() {
  //   this.stopListening();
  // }

  // startListening() {
  //   scrollParent().addEventListener('scroll', this.onScroll);
  // }

  // stopListening() {
  //   scrollParent().removeEventListener('scroll', this.onScroll);
  // }

  render() {
    return (
      <div className={this.props.className}>
        {this.props.cta}
        {React.cloneElement(this.props.fab, { isVisible: this.state.isVisible })}
      </div>
    );
  }
}

CtaToFab.propTypes = {
  className: PropTypes.string,
  cta: PropTypes.node,
  fab: PropTypes.node,
  scrollOffset: PropTypes.number,
};
