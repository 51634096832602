import PropTypes from 'prop-types';
import React from 'react';
import { connect } from '../runtime-context';
import Link from '../link/internal-link';
import { getCurrentSitePageTitle } from '../../store/basic-params/basic-params-selectors';
import styles from './header-title.scss';

export const HeaderTitle = ({ title, to }) => {
  const Component = to === undefined ? 'span' : Link;
  return (
    <h1 className={styles.title}>
      <Component to={to}>{title}</Component>
    </h1>
  );
};

HeaderTitle.propTypes = {
  title: PropTypes.string,
  to: PropTypes.string,
};

const mapStateToProps = state => ({
  title: getCurrentSitePageTitle(state),
});

export default connect(mapStateToProps)(HeaderTitle);
