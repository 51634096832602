import { flowRight } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import { LinkIcon } from '../../../icons/link-icon';
import ShareLink from '../../../share-link';
import withFontClassName from '../../../../hoc/with-font-class-name';
import withTranslate from '../../../../hoc/with-translate';
import styles from './share-link-form.scss';

const ShareLinkForm = ({ url, onCancel, titleFontClassName, t }) => (
  <div className={classNames(styles.container, 'blog-text-color', titleFontClassName)}>
    <div className={styles.message}>
      <LinkIcon className="blog-icon-fill" />
      <span className={styles.title}>{t('share-form.share-link')}</span>
    </div>

    <div className={styles.content}>
      <ShareLink url={url} onCancel={() => onCancel()} />
    </div>
  </div>
);

ShareLinkForm.propTypes = {
  url: PropTypes.string.isRequired,
  onCancel: PropTypes.func.isRequired,
  titleFontClassName: PropTypes.string,
  t: PropTypes.func,
};

export default flowRight(withFontClassName, withTranslate)(ShareLinkForm);
