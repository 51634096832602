import PropTypes from 'prop-types';
import React, { Component } from 'react';
import classNames from 'classnames';
import { ImgBackground } from '@wix/communities-image-lib';
import withResponsiveContext from '../../../common/components/responsive-listener/with-responsive-context';
import styles from './cover.scss';

class Cover extends Component {
  constructor(props) {
    super(props);

    this.state = {
      width: props.width || props.rootWidth,
      height: props.height,
    };
  }

  render() {
    const { width, height } = this.state;
    const { className, cover, showCover, children, hasActiveState } = this.props;
    const containerClassName = classNames(styles.cover, className, {
      [styles.hasActiveState]: hasActiveState,
    });
    const image = showCover && cover ? cover : '';

    return (
      <ImgBackground className={containerClassName} src={image} width={width} height={height}>
        {children}
      </ImgBackground>
    );
  }
}

Cover.propTypes = {
  className: PropTypes.string,
  cover: PropTypes.object,
  showCover: PropTypes.bool,
  width: PropTypes.number,
  rootWidth: PropTypes.number,
  height: PropTypes.number.isRequired,
  children: PropTypes.node,
  hasActiveState: PropTypes.bool,
};

Cover.defaultProps = {
  showCover: true,
  hasActiveState: true,
};

export default withResponsiveContext(Cover);
