import PropTypes from 'prop-types';
import React, { Component } from 'react';
import classNames from 'classnames';

import styles from './modal.scss';
import { handleEscapeKeyUp, handleTabKeyUp } from '../../../services/accessibility';
import AccessibilityListener from '../../accessibility-listener';

export class ModalPage extends Component {
  setInitialFocusElement = ref => {
    this.initialFocusElement = ref;
  };

  focusInitialElement = () => {
    this.initialFocusElement && this.initialFocusElement.focus();
  };

  componentDidMount() {
    this.focusInitialElement();
  }

  render() {
    const { showBackdrop, children, onClose, className } = this.props;
    const containerClassName = classNames(className, styles.container, { [styles.withShadow]: !showBackdrop });

    return (
      <main
        ref={this.setInitialFocusElement}
        className={containerClassName}
        onKeyUp={handleEscapeKeyUp(onClose)}
        data-hook="modal-page"
        tabIndex="0"
      >
        <AccessibilityListener />
        {children}
        <div className={styles.focusWrapper} tabIndex="0" onKeyUp={handleTabKeyUp(this.focusInitialElement)} />
      </main>
    );
  }
}

ModalPage.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
  showBackdrop: PropTypes.bool,
};

export default ModalPage;
