import { flowRight } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import { HorizontalSeparator } from '../../../common/components/separator';
import PostFooter from '../../../common/components/post-footer';
import withFontClassName from '../../../common/hoc/with-font-class-name';
import withSeparatorColor from '../../../common/hoc/with-separator-color';
import withFeedMetadataSettings from '../../../common/hoc/with-feed-metadata-settings';
import withIsFeedDesignEnabled from '../../../common/hoc/with-is-feed-design-enabled';
import withFeedBorderWidth from '../../../common/hoc/with-feed-border-width';
import { getFeedColorClassName } from '../../../common/services/layout-config';
import Link from '../../../common/components/link/internal-link';
import styles from './post-excerpt.scss';

export const PostExcerpt = ({
  post,
  onLikeClick,
  titleFontClassName,
  contentFontClassName,
  postMetadataFontSize,
  separatorColor,
  isMetadataFooterVisible,
  getPostClassName,
  layoutType,
  applyFeedDesign,
  borderWidth,
}) => {
  const postLink = `/${post.slug}`;
  const containerClassName = classNames(
    styles.postExcerpt,
    'blog-card-background-color',
    getPostClassName(getFeedColorClassName(layoutType, 'background-color'), 'border-color'),
  );

  const containerStyle = applyFeedDesign ? { borderWidth } : { borderColor: separatorColor };

  return (
    <div className={containerClassName} style={containerStyle}>
      <div className={titleFontClassName} data-hook="post-title">
        <h2
          className={classNames(
            styles.postTitle,
            getPostClassName('title-font', getFeedColorClassName(layoutType, 'title-color')),
          )}
        >
          <object type="communities/link">
            <Link to={postLink} style={{ color: 'inherit' }}>
              {post.title}
            </Link>
          </object>
        </h2>
      </div>
      {isMetadataFooterVisible && (
        <div>
          <HorizontalSeparator
            className={classNames(styles.separator, getPostClassName('description-background-color'))}
          />
          <div
            style={{ fontSize: postMetadataFontSize }}
            className={classNames(styles.metaContainer, contentFontClassName)}
          >
            <PostFooter {...{ post, postLink, onLikeClick, displayIcons: false }} />
          </div>
        </div>
      )}
    </div>
  );
};

PostExcerpt.propTypes = {
  post: PropTypes.object.isRequired,
  onLikeClick: PropTypes.func.isRequired,
  titleFontClassName: PropTypes.string.isRequired,
  contentFontClassName: PropTypes.string.isRequired,
  postMetadataFontSize: PropTypes.number,
  separatorColor: PropTypes.string,
  isMetadataFooterVisible: PropTypes.bool,
  applyFeedDesign: PropTypes.bool.isRequired,
  getPostClassName: PropTypes.func.isRequired,
  borderWidth: PropTypes.number,
  layoutType: PropTypes.number,
};

PostExcerpt.defaultProps = {
  postMetadataFontSize: 12,
  isMetadataFooterVisible: true,
};

export default flowRight(
  withFontClassName,
  withSeparatorColor,
  withFeedMetadataSettings,
  withIsFeedDesignEnabled,
  withFeedBorderWidth,
)(PostExcerpt);
