import { flowRight } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from '../../components/runtime-context';
import { BUTTON_CREATE_POST } from '../../bi-events/bi-buttons';
import withAuth from '../../hoc/with-auth';

export const CreatePostHandler = ({ categorySlug, children, buttonClicked, forPublicUser, navigateWithinPostPage }) => {
  const handleClick = () => {
    buttonClicked({ action: BUTTON_CREATE_POST });
    navigateWithinPostPage(`/create-post${categorySlug ? `?categorySlug=${categorySlug}` : ''}`);
  };

  const handleClickDecorated = (...args) => {
    return forPublicUser(handleClick)(...args);
  };

  const child = React.Children.only(children);
  return React.cloneElement(child, { onClick: handleClickDecorated });
};

CreatePostHandler.propTypes = {
  categorySlug: PropTypes.string,
  children: PropTypes.node.isRequired,
  buttonClicked: PropTypes.func,
  forPublicUser: PropTypes.func,
  navigateWithinPostPage: PropTypes.func.isRequired,
};

const mapRuntimeToProps = (state, ownProps, actions) => ({
  buttonClicked: actions.buttonClicked,
  navigateWithinPostPage: actions.navigateWithinPostPage,
});

export default flowRight(connect(mapRuntimeToProps), withAuth)(CreatePostHandler);
