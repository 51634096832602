import React from 'react';
import FastFormContext from './fast-form-context';
import getDisplayName from 'react-display-name';
import hoistNonReactStatics from 'hoist-non-react-statics';

export const withFastForm = (options = {}, config = {}) => WrappedComponent => {
  class FastFormConnector extends React.Component {
    static contextType = FastFormContext;
    static displayName = `connect(${getDisplayName(WrappedComponent)})`;

    componentWillUnmount() {
      const resetOnUnmount = options.resetOnUnmount || this.props.resetOnUnmount || config.resetOnUnmount || false;
      const formName = this.props.formName || options.formName;

      if (resetOnUnmount) {
        this.context.resetForm(formName);
      }
    }

    render() {
      const { getApi } = this.context;

      return <WrappedComponent {...this.props} fastForm={getApi(options, this.props, config)} />;
    }
  }
  hoistNonReactStatics(FastFormConnector, WrappedComponent);

  return FastFormConnector;
};

export default withFastForm;
