import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import FluidAvatarImage from '../fluid-avatar-image';
import resolveUserImage from '../../services/resolve-user-image';
import styles from './header-avatar-image.scss';

const IMAGE_SIZE = parseInt(styles.IMAGE_SIZE, 10) * 2;

const HeaderAvatarImage = ({ user }) => (
  <div className={classNames(styles.container, 'header-avatar-image')}>
    <div className={styles.image}>
      <FluidAvatarImage image={resolveUserImage(user.image, { width: IMAGE_SIZE, height: IMAGE_SIZE })} />
    </div>
  </div>
);

HeaderAvatarImage.propTypes = {
  user: PropTypes.object.isRequired,
};

export default HeaderAvatarImage;
