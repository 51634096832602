import PropTypes from 'prop-types';
import React, { Component } from 'react';
import hoistNonReactStatics from 'hoist-non-react-statics';
import { NAVIGATION_BACKGROUND_COLOR_PATH } from '@wix/communities-blog-client-common/dist/src/constants/wix-params';
import withAppSettings from './with-app-settings';
import getDisplayName from '../services/get-display-name';

const REGEXP = /(rgba\((?:\d+,){3})([0-9.]+)(\))/;

export default function withNavigationBackgroundColor(WrappedComponent) {
  const Wrapper = class extends Component {
    static displayName = `WithNavigationBackgroundColor(${getDisplayName(WrappedComponent)})`;

    static propTypes = {
      navigationBackgroundColor: PropTypes.object,
    };

    render() {
      const { navigationBackgroundColor, ...otherProps } = this.props;
      const color = navigationBackgroundColor ? navigationBackgroundColor.value.replace(REGEXP, '$11$3') : '';

      return <WrappedComponent navigationBackgroundColor={color} {...otherProps} />;
    }
  };

  hoistNonReactStatics(Wrapper, WrappedComponent);

  return withAppSettings({
    navigationBackgroundColor: NAVIGATION_BACKGROUND_COLOR_PATH,
  })(Wrapper);
}
