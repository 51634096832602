import { flowRight } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { ModalCentered } from '../base/modal-centered';
import ShareForm from '../../share-form';
import withTranslate from '../../../hoc/with-translate';

const SharePostModal = ({ closeModal, postSlug, postId, t }) => (
  <ModalCentered onClose={closeModal}>
    <ShareForm title={t('share-post-modal.share-post')} path={`/${postSlug}`} postId={postId} />
  </ModalCentered>
);

SharePostModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  postSlug: PropTypes.string.isRequired,
  postId: PropTypes.string,
  t: PropTypes.func.isRequired,
};

export default flowRight(withTranslate)(SharePostModal);
