import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import { Img } from '@wix/communities-image-lib';

import styles from './post-list-item-image.scss';

const PostListItemImageMobile = ({ image, url, isPublic }) => {
  const props = isPublic ? { 'data-pin-url': url } : { 'data-pin-nopin': true };
  return (
    <div className={classNames(styles.container, styles.mobile)}>
      <Img src={image} className={styles.image} {...props} />
    </div>
  );
};

PostListItemImageMobile.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
  url: PropTypes.string,
  isPublic: PropTypes.bool,
};

export default PostListItemImageMobile;
