import PropTypes from 'prop-types';
import React from 'react';
import DesktopHeader from '../../../common/components/desktop-header';
import HeaderNavigation from '../../../common/components/header-navigation';
import SearchHeader from './search-header';

const SearchDesktopHeader = props => (
  <SearchHeader
    component={DesktopHeader}
    left={<HeaderNavigation />}
    inputClassName="blog-desktop-header-search-text-color blog-desktop-header-search-font"
    borderClassName="blog-desktop-header-search-border-background-color"
    iconClassName="blog-desktop-header-search-icon-fill"
    {...props}
  />
);

SearchDesktopHeader.propTypes = {
  routes: PropTypes.array,
  params: PropTypes.object,
};

export default SearchDesktopHeader;
