import {
  SOCIAL_SHARING_FACEBOOK,
  SOCIAL_SHARING_TWITTER,
  SOCIAL_SHARING_LINKED_IN,
  SOCIAL_SHARING_LINK,
} from '@wix/communities-blog-client-common/dist/src/constants/social-sharing';
import { FacebookIcon } from '../components/icons/facebook-icon';
import { TwitterIcon } from '../components/icons/twitter-icon';
import { LinkedInIcon } from '../components/icons/linked-in-icon';
import { LinkIcon } from '../components/icons/link-icon';

const ICONS = {
  [SOCIAL_SHARING_FACEBOOK]: FacebookIcon,
  [SOCIAL_SHARING_TWITTER]: TwitterIcon,
  [SOCIAL_SHARING_LINKED_IN]: LinkedInIcon,
  [SOCIAL_SHARING_LINK]: LinkIcon,
};

export const getSocialSharingIcon = provider => ICONS[provider];
