export const COMMENT_ACTION_SHARE = 'share';
export const COMMENT_ACTION_REPORT = 'report';
export const COMMENT_ACTION_EDIT = 'edit';
export const COMMENT_ACTION_DELETE = 'delete';

export const COMMENT_ACTIONS = [
  COMMENT_ACTION_SHARE,
  COMMENT_ACTION_REPORT,
  COMMENT_ACTION_EDIT,
  COMMENT_ACTION_DELETE,
];
