import { chain } from 'lodash';
import { COMMENT_ACTIONS, COMMENT_ACTION_SHARE, COMMENT_ACTION_EDIT } from '../constants/comment-actions';

export const getCommentActions = ({
  actions = COMMENT_ACTIONS,
  comment,
  canSee,
  enableShare = true,
  enableEdit = true,
}) =>
  chain(actions)
    .filter(action => canSee(action, 'comment', comment))
    .without(enableShare || COMMENT_ACTION_SHARE, enableEdit || COMMENT_ACTION_EDIT)
    .value();
