import { chain, map, take } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from '../runtime-context';
import classNames from 'classnames';
import { getCategoryPath } from '../../store/app-config/app-config-selectors';
import Link from '../link/internal-link';
import List from '../list';
import { getCategoriesMap } from '../../selectors/categories-selectors';
import styles from './category-label-list.scss';

const DISPLAY_COUNT = 3;

const renderCategoryItem = (category, index, LinkComponent, categoryPath) =>
  category && (
    <LinkComponent
      className={classNames('blog-text-color', 'post-categories-list__link')}
      to={`/${categoryPath}/${category.slug}`}
      key={index}
      data-hook="category-label-list__item"
    >
      {category.menuLabel}
    </LinkComponent>
  );

export const CategoryLabelList = ({ post, categories, isCategoryLinksEnabled, categoryPath }) => {
  let categoriesToDisplay;

  if (post.categories) {
    categoriesToDisplay = take(post.categories, DISPLAY_COUNT);
  } else {
    categoriesToDisplay = chain(categories)
      .pick(post.categoryIds)
      .values()
      .take(DISPLAY_COUNT)
      .value();
  }

  const count = categoriesToDisplay.length;

  if (!count) {
    return null;
  }

  const LinkComponent = isCategoryLinksEnabled ? Link : 'span';

  return (
    <List
      containerClassName={styles.container}
      itemClassName={styles.item}
      separatorClassName={styles.separator}
      separator={<span>&bull;</span>}
    >
      {map(categoriesToDisplay, (category, index) => renderCategoryItem(category, index, LinkComponent, categoryPath))}
    </List>
  );
};

CategoryLabelList.propTypes = {
  categories: PropTypes.object.isRequired,
  post: PropTypes.object.isRequired,
  isCategoryLinksEnabled: PropTypes.bool,
  categoryPath: PropTypes.string,
};

CategoryLabelList.defaultProps = {
  isCategoryLinksEnabled: true,
};

const mapRuntimeToProps = state => ({
  categories: getCategoriesMap(state),
  categoryPath: getCategoryPath(state),
});

export default connect(mapRuntimeToProps)(CategoryLabelList);
