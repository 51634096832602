import React from 'react';

export const XLarge = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="30"
    height="30"
    viewBox="0 0 30 30"
    style={{
      fill: '#fff',
      fillRule: 'evenodd',
    }}
    {...props}
  >
    <path
      d="M10976.4,1024.73l-14.3,14.28,13.9,13.92-0.7.74-14-13.92-14.2,14.25-0.8-.74,14.3-14.25-14.6-14.61,0.7-.74,14.6,14.61,14.3-14.28Z"
      transform="translate(-10946.15 -1023.83)"
    />
  </svg>
);
