import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ReadOnlyInput from '../read-only-input';
import ButtonGroup from '../button-group';
import withTranslate from '../../hoc/with-translate';

let Clipboard;
try {
  Clipboard = require('clipboard');
} catch (e) {}
import styles from './share-link.scss';

class ShareLink extends Component {
  state = {
    isClipboardSupported: false,
  };

  componentDidMount() {
    const isSupported = Clipboard.isSupported();
    this.setState({
      isClipboardSupported: isSupported,
    });

    if (isSupported) {
      this.clipboard = new Clipboard(`.${styles.container} [data-clipboard-text]`);

      this.clipboard.on('success', () => {
        document.querySelector(`.${styles.container} [data-clipboard-text]`).focus();
      });
    }
  }

  setButton = button => (this.button = button);

  render() {
    const { t, onCancel, url } = this.props;
    const { isClipboardSupported } = this.state;
    let primaryButtonProps;

    if (isClipboardSupported) {
      primaryButtonProps = {
        'data-clipboard-text': url,
        keepFocusStyles: true,
      };
    } else {
      primaryButtonProps = {
        style: {
          display: 'none',
        },
      };
    }

    return (
      <div className={styles.container}>
        <div className={styles.input}>
          <ReadOnlyInput value={url} autoFocus />
        </div>
        <ButtonGroup
          primaryButtonText={t('share-link.copy')}
          primaryButtonProps={primaryButtonProps}
          secondaryButtonText={t('share-link.cancel')}
          secondaryButtonProps={{ onClick: onCancel }}
        />
      </div>
    );
  }
}

ShareLink.propTypes = {
  url: PropTypes.string.isRequired,
  onCancel: PropTypes.func.isRequired,
  t: PropTypes.func,
};

export default withTranslate(ShareLink);
