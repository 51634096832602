import { omit } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { SANTA_MEMBERS_APP_ID } from '@wix/communities-universal/dist/src/constants/appsConfig';
import Wix from '../../services/wix-sdk-polyfill';
import { debug } from '../../services/debug';

const MembersAreaLink = ({ appDefinitionId, appPageId, onClick, children, viewedUser, ...props }) => {
  const linkProps = omit(props, 'dispatch', 'user');

  const handleClick = event => {
    if (onClick) {
      onClick(event);
    }
    if (event.defaultPrevented) {
      return;
    }

    event.preventDefault();
    event.stopPropagation();

    Wix.getPublicAPI(
      { appDefinitionId: SANTA_MEMBERS_APP_ID },
      api => {
        api
          .navigateToSection({
            appDefinitionId,
            appPageId,
            viewedUser,
          })
          .catch(debug.error);
      },
      debug.error,
    );
  };

  return (
    <a onClick={handleClick} href="#" {...linkProps}>
      {children}
    </a>
  );
};

MembersAreaLink.propTypes = {
  appDefinitionId: PropTypes.string,
  appPageId: PropTypes.string,
  children: PropTypes.node,
  viewedUser: PropTypes.string,
  onClick: PropTypes.func,
};

export default MembersAreaLink;
