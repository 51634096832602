import {
  MODAL_TYPE_SHARE_POST,
  MODAL_TYPE_DELETE_POST,
  MODAL_TYPE_DEMO_MODE,
  MODAL_TYPE_SHARE_LINK,
} from '@wix/communities-blog-client-common/dist/src/constants/modal-types';

import SharePostModal from '../../../common/components/modals/share-post-modal/share-post-modal-component';
import DeletePostModal from '../../../post-page/containers/delete-post-modal';
import DemoModeModal from '../../../post-page/containers/demo-modal';
import ShareLinkModal from '../../../common/components/modals/share-link-modal/share-link-modal';

export const modalComponentMapByType = {
  [MODAL_TYPE_SHARE_POST]: SharePostModal,
  [MODAL_TYPE_DELETE_POST]: DeletePostModal,
  [MODAL_TYPE_DEMO_MODE]: DemoModeModal,
  [MODAL_TYPE_SHARE_LINK]: ShareLinkModal,
};
