import { isArray } from 'lodash';
import PropTypes from 'prop-types';
import React, { forwardRef } from 'react';
import classNames from 'classnames';
import { HorizontalSeparator } from '../separator';
import { handleEnterKeyUp } from '../../services/accessibility';
import styles from './action-button.scss';

const ActionButton = forwardRef(({ className, onClick, children, disabled, ...props }, ref) => {
  const icon = isArray(children) ? children[0] : null;
  const text = isArray(children) ? children[1] : children;
  const rest = isArray(children) ? children.slice(2) : null;

  const handleClick = disabled ? () => {} : onClick;

  return (
    <button
      onClick={handleClick}
      onKeyPress={handleEnterKeyUp(handleClick)}
      className={classNames(styles.actionItem, className, 'action-button', disabled && styles.disabled)}
      role="menuitem"
      aria-label={text}
      ref={ref}
      {...props}
    >
      <div aria-hidden="true" className={styles.actionWrapper}>
        {icon && <div className={classNames(styles.icon, 'blog-icon-fill')}>{icon}</div>}
        <div
          aria-hidden="true"
          className={classNames(
            styles.actionText,
            'blog-text-color',
            'action-button__text',
            disabled && styles.disabled,
          )}
        >
          {text}
        </div>
        {rest}
      </div>
      <div className={styles.separator}>
        <HorizontalSeparator />
      </div>
    </button>
  );
});

ActionButton.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
  children: PropTypes.node.isRequired,
  disabled: PropTypes.bool,
};

export default ActionButton;
