import React from 'react';

export const AlertIcon = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="17"
    height="15"
    viewBox="0 0 17 15"
    style={{
      fillRule: 'evenodd',
    }}
    {...props}
  >
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-733.000000, -3384.000000)" fill="#008FED">
        <g transform="translate(595.000000, 3136.000000)">
          <g transform="translate(138.000000, 246.000000)">
            <path d="M12.309,9.627 C13.4444444,8.92660641 14.1350028,7.68708438 14.133,6.353 C14.1320904,4.8782692 13.2899179,3.53328428 11.963641,2.88844223 C10.6373641,2.24360018 9.0594193,2.41191276 7.899,3.322 C7.292,2.458 6.249,2 5.274,2 C3.373,2 1.827,3.547 1.827,5.4 C1.827,6.586 2.433,7.708 3.43,8.32 L3.428,8.34 C1.111,9.212 0,11.088 0,14 C0,14.276 0.062,15 0.5,15 L4.069,15 C4.02489477,15.3315407 4.00184857,15.6655436 4,16 C4,16.276 4.062,17 4.5,17 L16.5,17 C17.021,17 17,16 17,16 C17,12.88 14.874,10.621 12.309,9.627 L12.309,9.627 Z M1,14 C1,11.604 2.112,9.771 4.058,9.182 C4.26412581,9.11962597 4.40715562,8.93227706 4.413,8.717 C4.417,8.557 4.434,8.236 4.448,8.084 C4.46847067,7.87542283 4.35668948,7.67621276 4.168,7.585 C3.341,7.185 2.827,6.316 2.827,5.4 C2.827,4.099 3.925,3 5.274,3 C5.977,3 6.771,3.333 7.193,4.033 C6.68102348,4.69817869 6.40265299,5.51360741 6.401,6.353 C6.401,7.701 7.122,8.92 8.254,9.627 C6.277,10.393 4.831,11.916 4.267,14 L1,14 Z M5,16 C5,13.312 6.663,11.115 8.895,10.44 C9.10181106,10.3773947 9.24500695,10.1890215 9.25,9.973 C9.254,9.789 9.262,9.607 9.279,9.433 C9.29917151,9.22448568 9.18747994,9.02543534 8.999,8.934 C8.00199832,8.46141967 7.36685817,7.45633278 7.368,6.353 C7.368,4.766 8.659,3.5 10.246,3.5 C11.833,3.5 13.124,4.766 13.124,6.353 C13.1251418,7.45633278 12.4900017,8.46141967 11.493,8.934 C11.3043604,9.02578823 11.1926898,9.2251999 11.213,9.434 C11.231,9.608 11.238,9.789 11.242,9.972 C11.2465656,10.1883935 11.389845,10.3772801 11.597,10.44 C13.829,11.115 16,13.292 16,16 L5,16 Z" />
          </g>
        </g>
      </g>
    </g>
  </svg>
);
