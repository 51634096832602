import PropTypes from 'prop-types';
import React from 'react';
import { connect } from '../../../common/components/runtime-context';
import classNames from 'classnames';

import { ARCHIVE_POSTS_PER_PAGE } from '@wix/communities-blog-client-common/dist/src/constants/pagination';
import PostListItem from '../../../search/components/post-search-list-item';
import Pagination from '../../../common/containers/pagination';
import { getSectionUrl } from '../../../common/store/app-config/app-config-selectors';
import { createArchivePageUrl } from '../../../common/services/pagination';
import styles from './archive-posts-list.scss';

const ArchivePostsMobileList = ({
  params: { year, month, pageIndex },
  posts,
  entityCount,
  onPostLikeClick,
  sectionUrl,
}) => (
  <div>
    {posts.map(post => (
      <div className={classNames(styles.listItemMobile, 'search-results-list__list-item')} key={post._id} id={post._id}>
        <PostListItem
          post={post}
          onLikeClick={onPostLikeClick}
          postMetadataFontSize={12}
          postDescriptionFontSize={16}
          postTitleFontSize={26}
          postDetailsLineCount={3}
        />
      </div>
    ))}
    <Pagination
      page={Number(pageIndex || 1)}
      entityCount={entityCount}
      pageSize={ARCHIVE_POSTS_PER_PAGE}
      createPageUrl={page => createArchivePageUrl(sectionUrl, year, month, page)}
    />
  </div>
);

ArchivePostsMobileList.propTypes = {
  onPostLikeClick: PropTypes.func.isRequired,
  posts: PropTypes.array.isRequired,
  entityCount: PropTypes.number.isRequired,
  fetchArchive: PropTypes.func.isRequired,
  push: PropTypes.func.isRequired,
  params: PropTypes.object.isRequired,
};

const mapRuntimeToProps = (state, ownProps, actions) => ({
  onPostLikeClick: actions.incrementPostLikeCount,
  sectionUrl: getSectionUrl(state),
});

export default connect(mapRuntimeToProps)(ArchivePostsMobileList);
