import PropTypes from 'prop-types';
import React from 'react';
import _ from 'lodash';
import classNames from 'classnames';
import UserAvatarImage from '../../../common/components/user-avatar-image';
import UserBadge from '../../../common/components/user-badge';
import styles from './comment-avatar.scss';

function CommentAvatar({ type, user = {} }) {
  return (
    <div className={classNames(styles.avatar, styles[type], 'blog-text-color')}>
      <UserAvatarImage type={type ? `comment${_.capitalize(type)}` : 'comment'} user={user} />
      <div className={classNames(styles.profileName, 'comment-avatar__profile-name')}>{user.name}</div>
      <UserBadge user={user} />
    </div>
  );
}

CommentAvatar.SEARCH = 'search';

CommentAvatar.propTypes = {
  user: PropTypes.object.isRequired,
  type: PropTypes.string,
};

export default CommentAvatar;
