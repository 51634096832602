import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';

import withCardBackgroundColor from '../../../hoc/with-card-background-color';
import styles from './modal.scss';

export const ModalLayout = ({ className, cardBackgroundColor, children }) => (
  <div
    className={classNames(styles.content, className, 'blog-default-card-background-color')}
    data-hook="modal-layout"
    style={{ backgroundColor: cardBackgroundColor }}
  >
    {children}
  </div>
);

ModalLayout.propTypes = {
  className: PropTypes.string,
  cardBackgroundColor: PropTypes.string,
  children: PropTypes.node,
};

export default withCardBackgroundColor(ModalLayout);
