import PropTypes from 'prop-types';
import React from 'react';
import { CSSTransitionGroup } from 'react-transition-group';
import styles from './styles.scss';
import { Gateway } from 'react-gateway';

const FloatingActionButton = ({ button, isVisible }) => {
  let component = null;
  if (isVisible) {
    component = button;
  }
  return (
    <Gateway into="floating-action-button">
      <CSSTransitionGroup
        className={styles.button}
        transitionName={styles}
        transitionEnterTimeout={600}
        transitionLeaveTimeout={600}
      >
        {component}
      </CSSTransitionGroup>
    </Gateway>
  );
};

FloatingActionButton.propTypes = {
  button: PropTypes.node.isRequired,
  isVisible: PropTypes.bool,
};

export default FloatingActionButton;
