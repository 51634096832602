import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import Button from '../button';
import styles from './button-group.scss';

const ButtonGroup = ({
  primaryButtonText,
  primaryButtonProps,
  isPrimaryButtonVisible,
  secondaryButtonText,
  secondaryButtonProps,
  isSecondaryButtonVisible,
}) => (
  <div className={styles.wrapper}>
    <div className={styles.container}>
      {renderButton(isSecondaryButtonVisible, secondaryButtonText, {
        ...secondaryButtonProps,
        isSecondary: true,
        className: classNames(styles.button, 'button-group__secondary-button'),
        'data-hook': 'cancel-button',
      })}
      {renderButton(isPrimaryButtonVisible, primaryButtonText, {
        ...primaryButtonProps,
        className: classNames(styles.button, 'button-group__primary-button'),
        'data-hook': 'submit-button',
      })}
    </div>
  </div>
);

function renderButton(isVisible, text, props) {
  if (!isVisible) {
    return null;
  }

  return (
    <div className={styles.buttonWrapper}>
      <Button {...props}>{text}</Button>
    </div>
  );
}

ButtonGroup.propTypes = {
  primaryButtonText: PropTypes.string,
  primaryButtonProps: PropTypes.object,
  isPrimaryButtonVisible: PropTypes.bool,
  secondaryButtonText: PropTypes.string,
  secondaryButtonProps: PropTypes.object,
  isSecondaryButtonVisible: PropTypes.bool,
};

ButtonGroup.defaultProps = {
  primaryButtonProps: {},
  isPrimaryButtonVisible: true,
  secondaryButtonProps: {},
  isSecondaryButtonVisible: true,
};

export default ButtonGroup;
