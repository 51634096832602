import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import { PlusIcon } from '../../../common/components/icons/plus-icon';
import withButtonColor from '../../../common/hoc/with-button-color';
import styles from './floating-create-button.scss';

const FloatingCreateButton = ({ buttonColor, ...otherProps }) => {
  const className = classNames(
    styles.button,
    'floating-create-button',
    'blog-default-button-background-color',
    'blog-button-primary-icon-fill',
  );
  const containerProps = _.omit(otherProps, [
    'currentUser',
    'isBlocked',
    'isOwner',
    'dispatch',
    'viewMode',
    'isDemoMode',
    'openModal',
    'isAuthenticated',
    'isPrivate',
    'forPublicUser',
  ]);

  return (
    <span className={className} style={{ backgroundColor: buttonColor }} {...containerProps}>
      <PlusIcon className="blog-card-fill" width="13" />
    </span>
  );
};

FloatingCreateButton.propTypes = {
  buttonColor: PropTypes.string.isRequired,
};

export default withButtonColor(FloatingCreateButton);
