import { flowRight } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from '../../../common/components/runtime-context';
import { ModalCentered } from '../../../common/components/modals/base/modal-centered';
import DemoConfirmation from './demo-confirmation';
import { getIsTemplate } from '../../../common/store/app-config/app-config-selectors';
import withTranslate from '../../../common/hoc/with-translate';

export const DemoModal = ({ closeModal, viewMode, t, isTemplate }) => {
  let type = viewMode;
  if (viewMode === 'site' && isTemplate) {
    type = 'template';
  }

  return (
    <ModalCentered onClose={closeModal}>
      <DemoConfirmation
        title={t('demo-modal.demo-mode')}
        message={t(`demo-modal.message-${type}`)}
        confirmButton={t(viewMode === 'preview' ? 'demo-modal.activate-forum' : 'demo-modal.confirm')}
        onConfirm={() => {
          closeModal(viewMode === 'preview');
        }}
        onCancel={closeModal}
      />
    </ModalCentered>
  );
};

DemoModal.propTypes = {
  viewMode: PropTypes.string.isRequired,
  closeModal: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  isTemplate: PropTypes.bool.isRequired,
};

const mapRuntimeToProps = state => ({
  isTemplate: getIsTemplate(state),
});

export default flowRight(withTranslate, connect(mapRuntimeToProps))(DemoModal);
