import React from 'react';
import { I18nextProvider } from 'react-i18next';
import 'reset-css';
import DirectionProvider, { DIRECTIONS } from 'react-with-direction/dist/DirectionProvider';
import { ImgProvider, RENDER_IMAGE, RENDER_IMAGE_WITH_PLACEHOLDER_INITIALLY } from '@wix/communities-image-lib';
import { initI18n } from '@wix/communities-blog-client-common/dist/src/services/i18n-provider';
import { connect } from '../../../common/components/runtime-context';
import ResponsiveListener from '../../../common/components/responsive-listener/responsive-listener';
import DirectionProviderWrapper from '../../../common/components/direction-provider-wrapper';
import PermissionsProvider from '../../../common/components/permissions-provider';
import Router from '../router';
import {
  getLanguage,
  isSeo,
  getIsMobile,
  isEditor,
  getIsRTL,
} from '../../../common/store/basic-params/basic-params-selectors';
import { isWebpSupported } from '../../../common/services/is-webp-supported';
import { getTranslations } from '../../../common/store/translations/translations-selectors';
import { FastFormProvider } from '../../../common/components/fast-form';
import { getFastFormSubmitAction } from '../../../common/components/fast-form/store/fast-form-selectors';
import '../../../rich-content/components/rich-content-editor/rich-content-editor.global.scss';
import pinterest from '../../../common/services/pinterest';
import {
  getPinterestEnabled,
  getIsCreatedWithResponsiveEditor,
} from '../../../common/selectors/app-settings-selectors';
import { withReduxStore } from '../../../common/components/runtime-context/with-redux-store';
import WindowResizeListener from '../../../common/components/window-resize-listener';
import { initDebug } from '../../../common/services/debug';
import {
  getIsDebugEnabled,
  getImageHost,
  getVideoHost,
  getSectionUrl,
} from '../../../common/store/app-config/app-config-selectors';
import { initImageUtils } from '../../../common/services/image-utils';
import { initVideoUtils } from '../../../common/services/video-utils';
import { initVideoEmbed } from '../../../common/services/video-embed';
import { initOuterUrl } from '../../../common/services/get-outer-url';

const FEED_MIN_WIDTH = 980;

class AppRoot extends React.Component {
  constructor(props) {
    super(props);
    const { language, isMobile, isPinterestEnabled, isDebugEnabled, imageHost, videoHost, sectionUrl } = this.props;
    pinterest.init({ language, isMobile, isPinterestEnabled });
    initDebug(isDebugEnabled);
    initImageUtils(imageHost);
    initVideoUtils(videoHost);
    initVideoEmbed(videoHost);
    initOuterUrl(sectionUrl);
  }

  componentWillUnmount() {
    pinterest.removeEventListener();
  }

  getI18nConfig = (language, translations) => {
    if (this.lastLanguage !== language) {
      this.i18nConfig = initI18n(language, translations);
      this.lastLanguage = language;
    }
    return this.i18nConfig;
  };

  render() {
    const { addResizeListener, language, translations, direction, submitAction, isSeo } = this.props;
    const i18n = this.getI18nConfig(language, translations);

    return (
      <ResponsiveListener>
        <PermissionsProvider>
          <I18nextProvider i18n={i18n}>
            <DirectionProviderWrapper>
              <DirectionProvider direction={direction}>
                <ImgProvider
                  renderMode={isSeo ? RENDER_IMAGE : RENDER_IMAGE_WITH_PLACEHOLDER_INITIALLY}
                  {...(isWebpSupported() ? { format: 'webp' } : {})}
                >
                  <FastFormProvider
                    submitAction={submitAction}
                    // onSubmit={actions.fastFormSubmitCallback} // unused currently, when used need to tranform contentState
                  >
                    <Router />
                    {addResizeListener && <WindowResizeListener minWidth={FEED_MIN_WIDTH} />}
                  </FastFormProvider>
                </ImgProvider>
              </DirectionProvider>
            </DirectionProviderWrapper>
          </I18nextProvider>
        </PermissionsProvider>
      </ResponsiveListener>
    );
  }
}

const mapRuntimeToProps = state => {
  const isInClassicEditor = isEditor(state) && !getIsCreatedWithResponsiveEditor(state);
  return {
    language: getLanguage(state),
    translations: getTranslations(state),
    direction: getIsRTL(state) ? DIRECTIONS.RTL : DIRECTIONS.LTR,
    isSeo: isSeo(state),
    isMobile: getIsMobile(state),
    isPinterestEnabled: getPinterestEnabled(state),
    submitAction: getFastFormSubmitAction(state),
    addResizeListener: isInClassicEditor,
    isDebugEnabled: getIsDebugEnabled(state),
    imageHost: getImageHost(state),
    videoHost: getVideoHost(state),
    sectionUrl: getSectionUrl(state),
  };
};

export default withReduxStore(connect(mapRuntimeToProps)(AppRoot));
