import { find } from 'lodash';
import { createSelector } from 'reselect';

export const getSearch = state => state.search;

export const getSearchResult = state => state.search.posts;

export const getUsers = state => state.search.members;

const getUserId = (state, id) => id;

export const getUserById = createSelector([getUsers, getUserId], (users, id) => find(users, ['_id', id]));
