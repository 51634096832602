import { flowRight, omit } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from '../runtime-context';
import classNames from 'classnames';

import withTranslate from '../../hoc/with-translate';
import { getUserNotificationCount } from '../../selectors/user-stats-selectors';
// import { fetchUserStats } from '../../actions/fetch-user-stats';
import IconButton from '../icon-button';
import { NotificationIcon } from '../icons/notification-icon';
import styles from './notification-button.scss';

class NotificationButton extends Component {
  // componentDidMount() {
  //   const { count, fetchUserStats } = this.props;
  //   if (count === undefined) {
  //     fetchUserStats();
  //   }
  // }

  render() {
    const { bubbleClassName, className, count, t, ...otherProps } = this.props;
    const bubbleCssClass = classNames(styles.bubble, 'notification-button__bubble', bubbleClassName);

    return (
      <IconButton
        className={classNames('notification-button', className)}
        icon={NotificationIcon}
        label={t('header.notifications')}
        aria-label={t('header.notifications')}
        {...omit(otherProps, 'dispatch', 'fetchUserStats')}
      >
        {count ? (
          <span className={bubbleCssClass} data-hook="notification-bubble">
            {count}
          </span>
        ) : null}
      </IconButton>
    );
  }
}

NotificationButton.propTypes = {
  count: PropTypes.number,
  bubbleClassName: PropTypes.string,
  className: PropTypes.string,
  iconClassName: PropTypes.string,
  // fetchUserStats: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  count: getUserNotificationCount(state),
});

// const mapDispatchToProps = {
//   fetchUserStats,
// };

// prettier-ignore
export default flowRight(
  connect(mapStateToProps),
  withTranslate
)(NotificationButton);
