import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import classNames from 'classnames';
import PostListItem from '../post-search-list-item';
import CommentListItem from '../comment-search-list-item';
import styles from './search-results-list.scss';

const renderItem = props => {
  // eslint-disable-next-line react/prop-types
  const { post, query, onPostLikeClick, onCommentLikeClick } = props;
  const isPost = post.type === 'post';
  const Component = isPost ? PostListItem : CommentListItem;
  const componentProps = {
    [isPost ? 'post' : 'comment']: post,
    onLikeClick: isPost ? onPostLikeClick : onCommentLikeClick,
    query,
    postMetadataFontSize: 12,
    postDescriptionFontSize: 16,
    postTitleFontSize: 26,
  };

  return (
    <div className={classNames(styles.listItemMobile, 'search-results-list__list-item')} key={post._id} id={post._id}>
      <Component type="search" {...componentProps} postDetailsLineCount={3} />
    </div>
  );
};

const SearchResultsMobileList = props => <Fragment>{props.posts.map(post => renderItem({ ...props, post }))}</Fragment>;

SearchResultsMobileList.propTypes = {
  onPostLikeClick: PropTypes.func.isRequired,
  onCommentLikeClick: PropTypes.func.isRequired,
  posts: PropTypes.array,
  location: PropTypes.object,
  query: PropTypes.string,
};

export default SearchResultsMobileList;
