import PropTypes from 'prop-types';
import React from 'react';
import CtaToFab from '../cta-to-fab';
import CreatePostButton from '../create-post-button';
import FloatingActionButton from '../floating-action-button';
import FloatingCreateButton from '../floating-create-button';
import CreatePostHandler from '../../../common/containers/create-post-handler';
import ensureAuth from '../../../common/hoc/ensure-auth';

const MOBILE_SCROLL_OFFSET = 50;

// eslint-disable-next-line new-cap
const FloatingCreateButtonSecure = ensureAuth(FloatingCreateButton);

const CreatePostCta = ({ categorySlug }) => {
  const floatingButton = (
    <CreatePostHandler categorySlug={categorySlug}>
      <FloatingCreateButtonSecure />
    </CreatePostHandler>
  );

  return (
    <CtaToFab
      cta={<CreatePostButton categorySlug={categorySlug} />}
      fab={<FloatingActionButton button={floatingButton} />}
      scrollOffset={MOBILE_SCROLL_OFFSET}
    />
  );
};

CreatePostCta.propTypes = {
  categorySlug: PropTypes.string,
};

export default CreatePostCta;
