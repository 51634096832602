import { flowRight, isUndefined } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from '../runtime-context';
import withResponsiveContext from '../responsive-listener/with-responsive-context';
import withTranslate from '../../hoc/with-translate';
import classNames from 'classnames';

import { ProGallery, GALLERY_CONSTS } from 'pro-gallery';

import 'pro-gallery/dist/statics/main.css';
import {
  isLayoutPGSideBySide,
  isLayoutPGOneColumn,
  isLayoutTextOnImage,
  isLayoutSlider,
  isLayoutList,
} from '@wix/communities-blog-client-common/dist/src/constants/layout-types';
import Wix from '../../services/wix-sdk-polyfill';
import { getLayoutConfig } from '../../services/layout-config';
import { getWrappersHorizontalMargin, getContainerWidth } from '../../services/post-list-pro-gallery';
import withDeviceType from '../../hoc/with-device-type';
import withFeedBorderWidth from '../../hoc/with-feed-border-width';
import withFeedMetadataSettings from '../../hoc/with-feed-metadata-settings';
import {
  getIsPostListFullWidthEnabled,
  getLayoutPostSize,
  getLayoutSpacing,
  getLayoutContentHeight,
  getFeedBorderColor,
  getLayoutImageRatio,
  getLayoutMargins,
  getLayoutImageAlignment,
  getLayoutImageProportions,
  getIsCreatedWithResponsiveEditor,
  getLayoutSidesPadding,
} from '../../selectors/app-settings-selectors';
import PostListItemProGallery from '../post-list-item-pro-gallery';
import { getPostPageSectionUrl } from '../../store/topology/topology-selectors';
import LoadMore from '../load-more/load-more';
import Loader from '../loader';
import { getPostCover } from '../../services/get-post-cover';
import { resizeMediaUrl } from './resizeMediaUrl';
import { createOptions } from './galleryOptions';
import { isHoveringBehaviourNeverShow } from '../../selectors/pro-gallery-options-selectors';
import { TEXT_PLACEMENT } from '../../constants/pro-gallery-options';
import { isClickTargetItemMedia, isPGEventItemClicked } from '../../constants/pro-gallery-events';
import { getProGalleryViewMode } from '../../selectors/progallery-view-mode-selectors';
import { getIsMobile, getIsRTL } from '../../store/basic-params/basic-params-selectors';
import { SECTIONS, SECTION_POST_LIST } from '@wix/communities-blog-client-common/dist/src/constants/wix-params';
import styles from './post-list-pro-gallery.scss';
import { getIsTemplate } from '../../store/app-config/app-config-selectors';
import { getIsDemoMode } from '../../store/instance-values/instance-values-selectors';

const getPGEmptyCoverImage = layoutType => ({
  height: 1,
  width: 10000,
  ...(isLayoutTextOnImage(layoutType) || isLayoutSlider(layoutType) ? { type: 'text' } : {}),
});

class PostListProGallery extends Component {
  componentDidMount() {
    if (this.props.createdWithResponsiveEditor) {
      document.documentElement.classList.add('enable-scroll');
    }
  }

  getItemMetadata(post, hideCoverImage, emptyCoverImage) {
    const { shouldRender, imageMetadata, videoMetadata } = getPostCover(post);

    if (hideCoverImage || !shouldRender) {
      return emptyCoverImage;
    }

    if (imageMetadata) {
      return {
        type: 'image',
        height: imageMetadata.height,
        width: imageMetadata.width,
        alt: post.title,
      };
    } else if (videoMetadata) {
      // thumbnail_width and thumbnail_height are switched in the post.coverImage.videoMetadata
      return {
        type: 'video',
        height: videoMetadata.thumbnail_width,
        width: videoMetadata.thumbnail_height,
        poster: videoMetadata.thumbnail_url,
        alt: post.title,
      };
    }
  }

  getItemUrl(post) {
    const { shouldRender, imageMetadata, videoMetadata } = getPostCover(post);

    if (this.props.hideCoverImage || !shouldRender) {
      return '';
    }

    if (imageMetadata) {
      return imageMetadata.file_name;
    } else if (videoMetadata) {
      return videoMetadata.video_url;
    }
  }

  renderProGallery() {
    const {
      t,
      allPosts,
      borderColor,
      borderWidth,
      createdWithResponsiveEditor,
      hostWidth,
      layoutContentHeight,
      layoutImageAlignment,
      layoutImageProportions,
      layoutImageRatio,
      layoutMargins,
      layoutName,
      layoutPostSize,
      layoutSidesPadding,
      layoutSpacing,
      layoutType,
      onLikeClick,
      rootWidth,
      isRTL,
      section,
      domId,
      hideAuthorBadge,
      hideThreeDots,
      className,
      hideCoverImage,
      layoutOptions = {},
    } = this.props;
    let proGalleryKey = 'pg-posts-no-change';

    if (
      allPosts !== this.prevAllPosts ||
      hideCoverImage !== this.prevHideCoverImage ||
      layoutType !== this.prevLayoutType
    ) {
      proGalleryKey = 'pg-posts-change';

      const emptyCoverImage = getPGEmptyCoverImage(layoutType);
      this.items = allPosts.map(post => {
        return {
          itemId: post._id,
          metaData: this.getItemMetadata(post, hideCoverImage, emptyCoverImage),
          url: this.getItemUrl(post),
        };
      });

      this.prevAllPosts = allPosts;
      this.prevHideCoverImage = hideCoverImage;
      this.prevLayoutType = layoutType;
    }

    const layoutPGSideBySide = isLayoutPGSideBySide(layoutType);
    const layoutPGOneColumn = isLayoutPGOneColumn(layoutType);
    const layoutSlider = isLayoutSlider(layoutType);
    const layoutImageRatioValue = [null, 16 / 9, 4 / 3, 1, 3 / 4, 9 / 16, 16 / 7][Number(layoutImageRatio)];
    const textBoxAlignment = [null, TEXT_PLACEMENT.SHOW_ON_THE_LEFT, TEXT_PLACEMENT.SHOW_ON_THE_RIGHT][
      Number(layoutImageAlignment)
    ];

    const options = createOptions(
      layoutType,
      {
        layoutPostSize: isLayoutSlider(layoutType) ? hostWidth : layoutPostSize,
        layoutSpacing,
        layoutContentHeight,
        layoutImageRatio: layoutImageRatioValue,
        textBoxAlignment,
        textBoxProportions: Math.abs(layoutImageProportions - 100),
        isRTL,
        ...layoutOptions,
      },
      {
        width: borderWidth,
        color: borderColor,
      },
    );

    const container = {
      width: getContainerWidth({
        width: hostWidth || rootWidth,
        layoutMargins,
        createdWithResponsiveEditor,
        layoutPGSideBySide,
        layoutPGOneColumn,
        layoutPostSize,
        layoutSlider,
        borderWidth,
      }),
    };

    const scrollingElement = () => {
      try {
        if (typeof window !== 'undefined' && (window.top === window.self || createdWithResponsiveEditor)) {
          // todo in editorX preview return mock (for iframe)
          // OOI
          return window;
        } else {
          if (typeof window !== 'undefined' && this.props.isTemplate && this.props.isDemoMode) {
            return window.document.body;
          }

          // iFrame
          // return a "mock" of the window
          return {
            addEventListener: (eventName, callback) => {
              Wix.addEventListener(eventName.toUpperCase(), callback);
            },
            removeEventListener: (eventName, callback) => {
              Wix.removeEventListener(eventName.toUpperCase(), callback);
            },
          };
        }
      } catch (e) {
        console.error('Cannot get scrolling element', e);
        return {};
      }
    };

    const { navigateProGalleryWithinPostPage } = this.props;
    const eventsListener = (eventName, eventData) => {
      if (!isLayoutTextOnImage(layoutType) && isPGEventItemClicked(eventName) && isClickTargetItemMedia(eventData)) {
        const currentPost = allPosts.find(post => post._id === eventData.id);

        if (currentPost.slug) {
          navigateProGalleryWithinPostPage(`/${currentPost.slug}`);
        }
      }
    };

    const convertItemToPostData = itemProps => {
      const { itemConfig } = getLayoutConfig(layoutType);
      const currentPost = allPosts.find(post => post._id === itemProps.id);

      return {
        type: layoutName,
        post: currentPost,
        onLikeClick,
        itemConfig,
        itemProps,
      };
    };
    const postInfoRenderer = (itemProps, placement) => {
      if (isLayoutList(layoutType) && placement === 'SHOW_BELOW') {
        const containerClassName = classNames(
          'blog-post-post-list-background-color',
          styles.listLayoutFullHeightBackground,
        );
        return <div className={containerClassName}></div>;
      }

      return (
        <PostListItemProGallery
          {...convertItemToPostData(itemProps)}
          galleryOptions={options}
          layoutSidesPadding={layoutSidesPadding}
          section={section}
          hideThreeDots={hideThreeDots}
          hideAuthorBadge={hideAuthorBadge}
          textBoxAlignment={textBoxAlignment}
        />
      );
    };

    const horizontalMargin = getWrappersHorizontalMargin({
      layoutPGSideBySide,
      layoutPGOneColumn,
      layoutMargins,
      createdWithResponsiveEditor,
    });

    const wrapperStyles = {
      ...((layoutPGSideBySide || layoutPGOneColumn) && { maxWidth: layoutPostSize + borderWidth * 2 }),
    };

    if (section !== SECTION_POST_LIST) {
      wrapperStyles.marginTop = createdWithResponsiveEditor ? 20 : layoutMargins;
      wrapperStyles.marginBottom = createdWithResponsiveEditor ? 0 : layoutMargins;
      wrapperStyles.marginLeft = horizontalMargin;
      wrapperStyles.marginRight = horizontalMargin;
    }

    if (layoutSlider) {
      wrapperStyles.height = (hostWidth - layoutMargins * 2) / layoutImageRatioValue + layoutContentHeight;
    }

    return (
      <div
        data-hook="post-list-pro-gallery-container"
        style={wrapperStyles}
        className={classNames(
          `post-list-pro-gallery-${layoutName}`,
          textBoxAlignment === TEXT_PLACEMENT.SHOW_ON_THE_RIGHT ? 'gallery-left-align' : 'gallery-right-align',
          className,
        )}
      >
        <ProGallery
          key={`${proGalleryKey}-${layoutPostSize}`} // side by side layout doesn't update on layoutPostSize option change
          domId={domId || 'pro-blog'}
          allowSSR={true}
          items={this.items}
          options={options}
          container={container}
          scrollingElement={scrollingElement}
          eventsListener={eventsListener}
          viewMode={this.props.viewMode}
          formFactor={this.props.formFactor}
          customHoverRenderer={isHoveringBehaviourNeverShow(options) ? null : postInfoRenderer}
          customInfoRenderer={postInfoRenderer}
          customSlideshowInfoRenderer={postInfoRenderer}
          resizeMediaUrl={resizeMediaUrl}
          proGalleryRegionLabel={t('pro-gallery.parent-container-aria-label')}
        />
      </div>
    );
  }

  render() {
    if (!this.props.loadMore) {
      return this.renderProGallery();
    }

    const { isLoading, allPosts, entityCount, loadMore, pageStart } = this.props;

    return (
      <LoadMore
        loadMore={loadMore}
        loader={<Loader />}
        isLoading={isLoading}
        hasMore={allPosts.length < entityCount}
        pageStart={pageStart}
      >
        {this.renderProGallery()}
      </LoadMore>
    );
  }
}

PostListProGallery.propTypes = {
  t: PropTypes.func,
  onLikeClick: PropTypes.func.isRequired,
  loadMore: PropTypes.func,
  currentPagePosts: PropTypes.array,
  category: PropTypes.object,
  allPosts: PropTypes.array,
  location: PropTypes.object,
  query: PropTypes.string,
  isMobile: PropTypes.bool,
  layoutType: PropTypes.number.isRequired,
  layoutName: PropTypes.string.isRequired,
  entityCount: PropTypes.number,
  pageStart: PropTypes.number,
  isLoading: PropTypes.bool,
  showCreatePostAction: PropTypes.bool.isRequired,
  isMetadataFooterVisible: PropTypes.bool,
  isPostListFullWidthEnabled: PropTypes.bool.isRequired,
  postPageSectionUrl: PropTypes.string,
  hostWidth: PropTypes.number,
  navigateProGalleryWithinPostPage: PropTypes.func,
  layoutPostSize: PropTypes.number,
  layoutSpacing: PropTypes.number,
  layoutContentHeight: PropTypes.number,
  layoutImageRatio: PropTypes.number,
  layoutMargins: PropTypes.number,
  layoutImageAlignment: PropTypes.number,
  layoutImageProportions: PropTypes.number,
  viewMode: PropTypes.string,
  formFactor: PropTypes.oneOf([GALLERY_CONSTS.formFactor.MOBILE, GALLERY_CONSTS.formFactor.DESKTOP]),
  borderWidth: PropTypes.number.isRequired,
  layoutSidesPadding: PropTypes.number.isRequired,
  borderColor: PropTypes.object.isRequired,
  createdWithResponsiveEditor: PropTypes.bool,
  rootWidth: PropTypes.number,
  isRTL: PropTypes.bool.isRequired,
  hideAuthorBadge: PropTypes.bool,
  hideThreeDots: PropTypes.bool,
  layoutOptions: PropTypes.object,
  isTemplate: PropTypes.bool,
  hideCoverImage: PropTypes.bool,
  isDemoMode: PropTypes.bool,
  section: PropTypes.oneOf(SECTIONS),
};

const getLayoutStyleParamProps = ({ state, section, hostWidth, rootWidth, layoutMargins, layoutType }) => ({
  layoutContentHeight: getLayoutContentHeight({ state, section, layoutType }),
  layoutImageAlignment: getLayoutImageAlignment({ state, section, layoutType }),
  layoutImageProportions: getLayoutImageProportions({ state, section, layoutType }),
  layoutImageRatio: getLayoutImageRatio({ state, section, layoutType }),
  layoutPostSize: getLayoutPostSize({
    state,
    hostWidth,
    rootWidth,
    layoutMargins,
    section,
    layoutType,
  }),
  layoutSidesPadding: getLayoutSidesPadding({ state, section, layoutType }),
  layoutSpacing: getLayoutSpacing({ state, section, layoutType }),
});

const mapRuntimeToProps = (
  state,
  { section, layoutType, layoutName, rootWidth, layoutDefaults = {} },
  actions,
  host,
) => {
  const hostWidth = host.dimensions.width;
  const createdWithResponsiveEditor = getIsCreatedWithResponsiveEditor(state);
  const layoutMargins = createdWithResponsiveEditor
    ? null
    : getLayoutMargins({ state, section, layoutType, layoutName });
  const {
    layoutContentHeight,
    layoutImageAlignment,
    layoutImageProportions,
    layoutImageRatio,
    layoutPostSize,
    layoutSidesPadding,
    layoutSpacing,
  } = getLayoutStyleParamProps({
    state,
    rootWidth,
    hostWidth,
    layoutMargins,
    section,
    layoutType,
  });

  return {
    scroll: state.scroll,
    isPostListFullWidthEnabled: getIsPostListFullWidthEnabled(state),
    onLikeClick: actions.incrementPostLikeCount,
    postPageSectionUrl: getPostPageSectionUrl(state),
    hostWidth,
    navigateProGalleryWithinPostPage: actions.navigateProGalleryWithinPostPage,
    borderColor: getFeedBorderColor({ state, section }),
    createdWithResponsiveEditor,
    isRTL: getIsRTL(state),
    formFactor: getIsMobile(state) ? GALLERY_CONSTS.formFactor.MOBILE : GALLERY_CONSTS.formFactor.DESKTOP,
    layoutContentHeight: isUndefined(layoutContentHeight) ? layoutDefaults.contentHeight : layoutContentHeight,
    layoutImageAlignment: isUndefined(layoutImageAlignment) ? layoutDefaults.imageAlignment : layoutImageAlignment,
    layoutImageProportions: isUndefined(layoutImageProportions)
      ? layoutDefaults.imageProportions
      : layoutImageProportions,
    layoutImageRatio: isUndefined(layoutImageRatio) ? layoutDefaults.imageRatio : layoutImageRatio,
    layoutMargins: isUndefined(layoutMargins) ? layoutDefaults.margins : layoutMargins,
    layoutPostSize: isUndefined(layoutPostSize) ? layoutDefaults.layoutPostSize : layoutPostSize,
    layoutSidesPadding: isUndefined(layoutSidesPadding) ? layoutDefaults.sidesPadding : layoutSidesPadding,
    layoutSpacing: isUndefined(layoutSpacing) ? layoutDefaults.spacing : layoutSpacing,
    registerToScroll: host.registerToScroll,
    viewMode: getProGalleryViewMode(state),
    isTemplate: getIsTemplate(state),
    isDemoMode: getIsDemoMode(state),
  };
};

export default flowRight(
  withDeviceType,
  withFeedMetadataSettings,
  withTranslate,
  withResponsiveContext,
  connect(mapRuntimeToProps),
  withFeedBorderWidth,
)(PostListProGallery);
