import { get, flowRight } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from '../../../common/components/runtime-context';

import AnimatedLoader from '../../../common/components/animated-loader';
import Page from '../../../common/components/page';
import SearchPage from '../../../search/containers/search-page';
import DefaultDesktopLayout from '../../../common/components/default-desktop-layout';
import ArchivePostsList from '../../components/archive-posts-list';
import AppLoaded from '../../../common/components/app-loaded';
import { getArchive } from '../../../common/store/archive/archive-selectors';
import { getIsArchiveLoading } from '../../../common/store/is-loading/is-loading-selectors';
import { getIsArchiveLoaded } from '../../../common/store/is-loaded/is-loaded-selectors';

const ArchivePage = ({ posts, entityCount, params, isLoaded, isLoading }) => {
  const hasResults = Boolean(get(posts, 'length', 0));
  return (
    <Page noSpacing={!hasResults}>
      <DefaultDesktopLayout>
        <AnimatedLoader isLoading={isLoading} isAnimated={!isLoaded}>
          {hasResults ? <ArchivePostsList posts={posts} entityCount={entityCount} params={params} /> : <SearchPage />}
        </AnimatedLoader>
        {!isLoading && <AppLoaded />}
      </DefaultDesktopLayout>
    </Page>
  );
};

ArchivePage.propTypes = {
  posts: PropTypes.array.isRequired,
  entityCount: PropTypes.number.isRequired,
  params: PropTypes.object.isRequired,
  setIsLoaded: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isLoaded: PropTypes.bool.isRequired,
};

const mapRuntimeToProps = (state, ownProps, actions) => ({
  posts: get(getArchive(state), 'posts'),
  entityCount: get(getArchive(state), 'entityCount'),
  isLoading: getIsArchiveLoading(state),
  isLoaded: getIsArchiveLoaded(state),
  params: ownProps.params,
  setIsLoaded: actions.setIsLoaded,
});

export default flowRight(connect(mapRuntimeToProps))(ArchivePage);
