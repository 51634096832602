import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';

import { XLarge } from '../../icons/x-large';
import styles from './modal.scss';
import { handleEnterKeyUp } from '../../../services/accessibility';

const renderCloseButton = onClose => (
  <button
    className={classNames(styles.closeIcon, 'modal__close')}
    onClick={onClose}
    onKeyUp={handleEnterKeyUp(onClose)}
  >
    <XLarge />
  </button>
);

const ModalBackdrop = ({ onClose, showCloseButton, className }) => (
  <div>
    <div
      className={classNames(className, styles.backdrop, 'modal__overlay')}
      onClick={onClose}
      onKeyUp={handleEnterKeyUp(onClose)}
    />
    {showCloseButton ? renderCloseButton(onClose) : null}
  </div>
);

ModalBackdrop.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  showCloseButton: PropTypes.bool,
};

ModalBackdrop.defaultProps = {
  showCloseButton: true,
};

export default ModalBackdrop;
