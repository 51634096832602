import PropTypes from 'prop-types';
import React from 'react';
import { connect } from '../runtime-context';
import Header from '../header';
import { getCategories } from '../../selectors/categories-selectors';

const CategoryMobileHeader = ({ categories, menu, ...otherProps }) =>
  categories.length ? <Header menu={menu} {...otherProps} /> : <Header {...otherProps} />;

CategoryMobileHeader.propTypes = {
  categories: PropTypes.array,
  menu: PropTypes.node,
};

const mapStateToProps = state => ({
  categories: getCategories(state),
});

export default connect(mapStateToProps)(CategoryMobileHeader);
