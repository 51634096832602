import { has } from 'lodash';

const getPinterestApi = () => {
  const key = Object.keys(window).find(key => key.includes('PIN') && has(window[key], 'f.listen'));
  return window[key];
};

let _listener;
const createMouseOverListener = api => event => {
  const element = api.f.getEl(event || api.w.event);
  if (api.f.canHazButton(element) && api.f.getData(element, 'url')) {
    if (!api.v.hazHoverButton) {
      api.v.hazHoverButton = true;
    }
    api.f.showHoverButton(element);
  } else if (api.v.hazHoverButton && element !== api.s.hoverButton) {
    api.v.hazHoverButton = false;
    api.f.kill(api.s.hoverButton);
  }
};

const addEventListener = (retries = 5) => {
  const api = getPinterestApi();
  if (api) {
    _listener = createMouseOverListener(api);
    api.f.listen(document.body, 'mouseover', _listener);
  } else if (retries) {
    setTimeout(() => addEventListener(retries - 1), 200);
  }
};

const removeEventListener = () => {
  if (!_listener) {
    return;
  }
  const api = getPinterestApi();
  api && api.f.listen(document.body, 'mouseover', _listener, true);
  _listener = undefined;
};

const init = ({ isMobile, isPinterestEnabled, language }) => {
  if (typeof window === 'undefined' || isMobile || !isPinterestEnabled) {
    return;
  }

  const pinterestPinId = 'pinterest-pin-script';
  if (window.PinUtils || document.getElementById(pinterestPinId)) {
    return addEventListener();
  }

  const s = document.createElement('script');
  s.setAttribute('src', '//assets.pinterest.com/js/pinit.js');
  s.setAttribute('data-pin-save', 'true');
  s.setAttribute('data-pin-lang', language);
  s.async = true;
  s.onload = () => addEventListener();
  s.id = pinterestPinId;
  document.querySelector('body').appendChild(s);
};

export default {
  init,
  removeEventListener,
};
