import { flowRight } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import withDeviceType from '../../hoc/with-device-type';
import withTranslate from '../../hoc/with-translate';
import getVideoEmbedThumbUrl from '../../services/get-video-embed-thumb-url';
import { PlayArrow } from '../icons/play-arrow';
import { PlayIcon } from '../icons/play-icon';
import styles from './post-list-item-video-thumbnail.scss';

const getStyle = oembed => ({ backgroundImage: `url(${getVideoEmbedThumbUrl(oembed)})` });

// eslint-disable-next-line react/prop-types
const renderPlayArrow = ({ t, isMobile, withoutLabel }) =>
  isMobile && !withoutLabel ? (
    <div className={styles.button}>
      <PlayArrow className={styles.arrow} /> {t('video-embed.load-video')}
    </div>
  ) : (
    <span>
      <PlayIcon className={styles.icon} />
    </span>
  );

export const PostListItemVideoThumbnail = ({ oembed, t, isMobile, withoutLabel }) => (
  <div className={styles.container}>
    <div className={styles.thumbnail} style={getStyle(oembed)} role="img">
      <div className={styles.overlay} />
      {renderPlayArrow({ t, isMobile, withoutLabel })}
    </div>
  </div>
);

PostListItemVideoThumbnail.propTypes = {
  oembed: PropTypes.object.isRequired,
  isMobile: PropTypes.bool,
  t: PropTypes.func.isRequired,
  withoutLabel: PropTypes.bool,
};

// prettier-ignore
export default flowRight(
  withDeviceType,
  withTranslate
)(PostListItemVideoThumbnail);
