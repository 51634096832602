import React from 'react';

export const PlusIcon = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="11"
    viewBox="0 0 445 445"
    style={{
      fillRule: 'evenodd',
      clipRule: 'evenodd',
    }}
    {...props}
  >
    <polygon points="427.4,199.7 245.3,199.7 245.3,17.6 199.7,17.6 199.7,199.7 17.6,199.7 17.6,245.3 199.7,245.3 199.7,427.4 245.3,427.4 245.3,245.3 427.4,245.3" />
  </svg>
);
