import { flowRight } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';

import withHeaderFontSize from '../../hoc/with-header-font-size';
import withDeviceType from '../../../common/hoc/with-device-type';
import { getHeaderTextAlignmentClassName } from '../../services/page-description';
import PageCover from '../page-cover';
import styles from './page-description.scss';

const DEFAULT_HEIGHT = 200;

const getContainerStyle = (textColor = {}) => ({
  color: textColor.color,
});

export const PageDescription = props => {
  const {
    title,
    description,
    cover,
    coverType,
    overlayColor,
    backgroundColor,
    textColor = {},
    headerTextAlignment,
    children,
    headerSize,
    titleFontClassName,
    descriptionFontClassName,
    isMobile,
  } = props;

  const textAlignmentClassName = styles[getHeaderTextAlignmentClassName(headerTextAlignment)];
  const containerClassName = classNames(
    styles.container,
    'blog-default-desktop-header-text-color',
    'page-description',
    isMobile ? styles.mobile : styles.desktop,
    {
      [textAlignmentClassName]: textAlignmentClassName,
      [styles[`headerSize${headerSize}`]]: headerSize,
    },
  );

  return (
    <div className={containerClassName} style={getContainerStyle(textColor)} dir="ltr">
      <PageCover
        cover={cover}
        coverType={coverType}
        overlayColor={overlayColor}
        backgroundColor={backgroundColor}
        height={DEFAULT_HEIGHT}
        hasActiveState={false}
      >
        <div className={styles.wrapper}>
          <div
            className={classNames(styles.titleWrapper, 'blog-page-description-title-font', 'page-description__title')}
          >
            <h1 className={classNames(styles.title, titleFontClassName)} data-hook="page-description__title">
              {title}
            </h1>
          </div>
          <div className={classNames(styles.descriptionWrapper, 'blog-page-description-content-font')}>
            <p
              className={classNames(styles.description, descriptionFontClassName)}
              data-hook="page-description__description"
            >
              {description}
            </p>
          </div>
          {children}
        </div>
      </PageCover>
    </div>
  );
};

PageDescription.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  cover: PropTypes.object,
  coverType: PropTypes.string,
  overlayColor: PropTypes.object,
  backgroundColor: PropTypes.object,
  textColor: PropTypes.object,
  headerTextAlignment: PropTypes.number,
  children: PropTypes.node,
  headerSize: PropTypes.number,
  titleFontClassName: PropTypes.string,
  descriptionFontClassName: PropTypes.string,
  isMobile: PropTypes.bool,
};

// prettier-ignore
export default flowRight(
  withHeaderFontSize,
  withDeviceType
)(PageDescription);
