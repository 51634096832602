import { flowRight } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import { connect } from '../runtime-context';

import LikeButtonWithCount from '../like-button-with-count';
import PostSocialActions from '../post-social-actions';
import ViewCount from '../view-count';
import CommentCountOrNew from '../../../comments/components/comment-count-or-new';
import CategoryLabelList from '../category-label-list';
import { HorizontalSeparator } from '../separator';
import withPermissions from '../../hoc/with-permissions';
import withAuth from '../../hoc/with-auth';
import withFeedMetadataSettings from '../../hoc/with-feed-metadata-settings';
import { getFeedColorClassName } from '../../services/layout-config';
import { getViewCount, getCommentCount } from '../../store/post-counters/post-counters-selectors';
import { getSocialSharingProviders } from '../../selectors/app-settings-selectors';
import styles from './post-main-actions-desktop.scss';

const PostMainActionsDesktop = ({
  anySocialActionOrCategory,
  canRender,
  commentsLink,
  enabledProviders,
  forPublicUser,
  getPostClassName,
  layoutName,
  onLikeClick,
  post,
  showCategoryLabels,
  showCommentCount,
  showLikeCount,
  showViewCount,
  showShareButtons,
  viewCount,
  totalComments,
}) => {
  const showCommentCountOrNew = commentsLink || totalComments;
  const isMetadataVisible = showLikeCount || (showCommentCount && showCommentCountOrNew) || showViewCount;
  const noSeparators = !anySocialActionOrCategory && !isMetadataVisible;

  return (
    <div
      className={classNames(
        styles.container,
        { [styles.noSeparators]: noSeparators },
        getPostClassName('description-font', getFeedColorClassName(layoutName, 'description-color')),
      )}
      data-hook="post-main-actions-desktop"
    >
      {showShareButtons &&
        anySocialActionOrCategory && [
          <HorizontalSeparator key="separator" />,
          <div key="div" className={classNames(styles.section, styles.mainSection)}>
            {canRender('share', 'post', post, () => (
              <PostSocialActions path={`/${post.slug}`} postId={post._id} enabledProviders={enabledProviders} />
            ))}
            {showCategoryLabels && <CategoryLabelList post={post} />}
          </div>,
        ]}
      {isMetadataVisible && [
        <HorizontalSeparator key="separator" />,
        <div
          key="div"
          className={classNames(styles.section, styles.bottomSection)}
          data-hook="post-main-actions__stats"
        >
          <div className={styles.stats}>
            {showViewCount && <ViewCount count={viewCount} />}
            {showCommentCount && (
              <CommentCountOrNew
                count={totalComments}
                link={{
                  to: commentsLink,
                  className: classNames(styles.comments, getPostClassName('link-hashtag-hover-color')),
                  addHoverClasses: false,
                }}
              />
            )}
          </div>
          {showLikeCount && (
            <LikeButtonWithCount className={styles.likeButton} onClick={forPublicUser(onLikeClick)} entity={post} />
          )}
        </div>,
      ]}
    </div>
  );
};

PostMainActionsDesktop.defaultProps = {
  showShareButtons: true,
};

PostMainActionsDesktop.propTypes = {
  anySocialActionOrCategory: PropTypes.bool.isRequired,
  canRender: PropTypes.func,
  commentsLink: PropTypes.string,
  enabledProviders: PropTypes.array.isRequired,
  forPublicUser: PropTypes.func,
  getPostClassName: PropTypes.func.isRequired,
  layoutName: PropTypes.string,
  onLikeClick: PropTypes.func.isRequired,
  post: PropTypes.object.isRequired,
  showCategoryLabels: PropTypes.bool,
  showCommentCount: PropTypes.bool,
  showLikeCount: PropTypes.bool,
  showViewCount: PropTypes.bool,
  showShareButtons: PropTypes.bool,
  t: PropTypes.func,
  viewCount: PropTypes.number.isRequired,
  totalComments: PropTypes.number.isRequired,
};

const mapStateToProps = (state, { canSee, post, showCategoryLabels }, actions) => {
  const enabledProviders = getSocialSharingProviders(state);
  const socialVisible = canSee('share', 'post', post) && enabledProviders.length;
  const categoryVisible = showCategoryLabels && post.categoryIds && post.categoryIds.length;
  return {
    anySocialActionOrCategory: Boolean(socialVisible || categoryVisible),
    enabledProviders,
    onLikeClick: () => actions.incrementPostLikeCount(post._id),
    viewCount: getViewCount(state, post._id),
    totalComments: getCommentCount(state, post._id),
  };
};

// prettier-ignore
export default flowRight(
  withPermissions,
  withFeedMetadataSettings,
  connect(mapStateToProps),
  withAuth,
)(PostMainActionsDesktop);
