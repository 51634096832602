import PropTypes from 'prop-types';
import React, { Component } from 'react';
import classNames from 'classnames';
import ButtonGroup from '../button-group';
import withFontClassName from '../../hoc/with-font-class-name';
import styles from './confirmation.scss';

class Confirmation extends Component {
  getButtonProps = onClick => {
    return onClick ? { onClick: () => onClick() } : {};
  };

  render() {
    const {
      icon,
      title,
      children,
      confirm,
      onConfirm,
      cancel,
      onCancel,
      titleFontClassName,
      contentFontClassName,
      buttonProps,
    } = this.props;
    const Icon = icon;

    return (
      <div className={classNames(styles.container, contentFontClassName, 'blog-text-color')}>
        <h3 className={classNames(styles.titleContainer, titleFontClassName)}>
          {icon && <Icon className={classNames(styles.icon, 'blog-icon-fill')} />}
          {icon && <div className={styles.iconTitleSeparator} />}
          <span className={classNames(styles.title, 'confirmation__title')}>{title}</span>
        </h3>
        {children && <div className={styles.content}>{children}</div>}
        <ButtonGroup
          primaryButtonText={confirm}
          primaryButtonProps={this.getButtonProps(onConfirm)}
          secondaryButtonText={cancel}
          secondaryButtonProps={this.getButtonProps(onCancel)}
          {...buttonProps}
        />
      </div>
    );
  }
}

Confirmation.propTypes = {
  icon: PropTypes.any,
  title: PropTypes.string.isRequired,
  children: PropTypes.node,
  confirm: PropTypes.string.isRequired,
  onConfirm: PropTypes.func.isRequired,
  cancel: PropTypes.string,
  onCancel: PropTypes.func,
  titleFontClassName: PropTypes.string.isRequired,
  contentFontClassName: PropTypes.string.isRequired,
  buttonProps: PropTypes.object,
};

export default withFontClassName(Confirmation);
