import PropTypes from 'prop-types';
import React from 'react';
import { DeleteIcon } from '../icons/delete-icon';
import Confirmation from '../confirmation';
import withTranslate from '../../hoc/with-translate';

const DeleteConfirmation = ({ t, message, ...props }) => (
  <Confirmation
    icon={DeleteIcon}
    confirm={t('delete-confirmation.delete')}
    cancel={t('delete-confirmation.cancel')}
    {...props}
  >
    <p>{message}</p>
  </Confirmation>
);

DeleteConfirmation.propTypes = {
  title: PropTypes.string,
  message: PropTypes.string,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

export default withTranslate(DeleteConfirmation);
