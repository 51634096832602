import PropTypes from 'prop-types';
import React, { Component } from 'react';
import classNames from 'classnames';
import { getSocialSharingIcon } from '../../services/social-sharing-icons';
import styles from './share-button.scss';

class ShareButton extends Component {
  static propTypes = {
    type: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.handleClick = this.handleClick.bind(this);
  }

  handleClick() {
    const { onClick, type } = this.props;
    onClick(type);
  }

  render() {
    const { type, title } = this.props;
    const Icon = getSocialSharingIcon(type);
    return (
      <button
        className={classNames(styles.button, styles[type])}
        onClick={this.handleClick}
        aria-label={title}
        data-hook={`share-button__${type}`}
      >
        <Icon className={styles.icon} />
      </button>
    );
  }
}

export default ShareButton;
