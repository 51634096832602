import React from 'react';
import withLazyImport from '../../../common/hoc/with-lazy-import';

// this can be removed when rich-content version >= 7.9.1
const withLtr = Component => props => (
  <div dir="ltr">
    <Component {...props} />
  </div>
);

export default withLtr(
  withLazyImport(() =>
    import(/* webpackChunkName: "rich-content-fullscreen" */ 'wix-rich-content-fullscreen/dist/module.cjs'),
  ),
);
