import { flowRight } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import PageDescription from '../page-description';
import withCategoryTextAlignment from '../../hoc/with-category-text-alignment';
import withHeaderFontSize from '../../hoc/with-header-font-size';

const CategoryDescription = ({ category, headerSize, categoryTextAlignment }) => (
  <PageDescription
    title={category.label}
    description={category.description}
    cover={category.cover}
    coverType={category.coverType}
    overlayColor={category.overlayColor}
    backgroundColor={category.backgroundColor}
    textColor={category.textColor}
    headerSize={headerSize}
    headerTextAlignment={categoryTextAlignment}
  />
);

CategoryDescription.propTypes = {
  category: PropTypes.object.isRequired,
  categoryTextAlignment: PropTypes.number.isRequired,
  headerSize: PropTypes.number,
};

// prettier-ignore
export default flowRight(
  withCategoryTextAlignment,
  withHeaderFontSize
)(CategoryDescription);
