import { flowRight, isEqual } from 'lodash';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from '../runtime-context';
import classNames from 'classnames';

import { getAppData } from '../../selectors/app-data-selectors';
import { getCategories } from '../../selectors/categories-selectors';
import { getSearchInputWidth } from '../../store/search-input/search-input-selectors';
import { getLastUpdatedDate } from '../../selectors/app-settings-selectors';
import { getCurrentMatchPathname, getRouteParams } from '../../router/router-selectors';
import withTranslate from '../../hoc/with-translate';
import withAuth from '../../hoc/with-auth';
import { getHeaderLinks } from '../../services/get-header-links';
import Link from '../link/internal-link';
import MoreCategoriesDropdown from '../more-categories-dropdown';
import styles from './header-navigation.scss';

export class HeaderNavigation extends PureComponent {
  state = {
    visibleCategories: this.props.categories,
    moreCategories: [],
  };

  componentDidMount() {
    this.updateVisibleCategories();
  }

  componentDidUpdate(prevProps, prevState) {
    const propsKeys = ['isAuthenticated', 'searchInputWidth', 'viewMode', 'settingsUpdated', 'hostWidth'];
    const stateKeys = ['visibleCategories', 'moreCategories'];
    const propsChanged = propsKeys.some(key => !isEqual(prevProps[key], this.props[key]));
    const stateChanged = stateKeys.some(key => !isEqual(prevState[key], this.state[key]));
    if (propsChanged || stateChanged) {
      this.updateVisibleCategories();
    }
  }

  renderLink = ({ path, text, key }) => {
    const isActive = this.props.currentPathDecoded === path;

    const linkClassNames = classNames(
      styles.link,
      'blog-navigation-container-color blog-navigation-container-font ',
      'blog-navigation-link-hover-color',
      isActive && 'blog-navigation-link-active-color',
    );

    return (
      <li key={key} data-hook={`header-navigation__${path}`}>
        <Link className={linkClassNames} to={path} addHoverClasses={!true}>
          {text}
        </Link>
      </li>
    );
  };

  renderMoreButton = (categories, width) => {
    if (categories.length === 0) {
      return null;
    }
    const { t, appData, currentPath } = this.props;
    const links = getHeaderLinks(categories, appData);
    links.shift();

    return (
      categories && (
        <li>
          <MoreCategoriesDropdown
            currentPath={currentPath}
            items={links}
            width={width}
            moreText={t('header.more-button')}
            className="blog-navigation-container-color blog-navigation-container-hover-color blog-navigation-container-fill"
            hoverClassName="blog-navigation-container-hover-color blog-navigation-container-hover-fill"
            activeClassName="blog-navigation-container-active-color blog-navigation-container-active-fill"
          />
        </li>
      )
    );
  };

  updateVisibleCategories() {
    const { t, categories, appData } = this.props;
    if (typeof window === 'undefined') {
      return;
    }

    /*
     TL;DR: it's a workaround for calculating the category list and what should go to "more" categories dropdown.
     I'm generating a hidden div with the menu items and see if it fits the container,.
     I want to have the same styles (fonts) and boundaries, so I'm appending the div to the navigation container.
     With React.createElement I can't call domNode.appendChild (beause React.createElement d oesn't return a DOM node),
     and ReactDOM.findDOMNode should not be used in element's render().
     */
    const topNavigationContainer = document.querySelector('.blog-header__navigation');
    if (!topNavigationContainer) {
      return;
    }

    const container = document.createElement('div');

    const links = getHeaderLinks(categories, appData);
    container.className = `${styles.categoriesContainer} blog-navigation-container-font`;
    links.forEach(link => {
      const ch = document.createElement('a');
      ch.innerText = link.text;
      ch.id = link.key;
      container.appendChild(ch);
    });

    const moreButton = document.createElement('a');
    moreButton.innerText = t('header.more-button');
    moreButton.id = 'header-more-button';
    moreButton.className =
      'blog-navigation-container-color blog-navigation-container-hover-color blog-navigation-container-font';
    moreButton.style.paddingRight = '19px';

    topNavigationContainer.appendChild(container);
    const visibleCategories = Array.from(categories);
    const moreCategories = [];

    while (container.offsetWidth > topNavigationContainer.offsetWidth && visibleCategories.length) {
      if (!document.getElementById('header-more-button')) {
        container.appendChild(moreButton);
      }
      const invisibleCategory = visibleCategories.pop();
      const node = document.getElementById(invisibleCategory._id);
      if (node) {
        container.removeChild(node);
      }
      moreCategories.unshift(invisibleCategory);
    }

    const moreCategoriesMenuWidth =
      topNavigationContainer.parentElement.offsetWidth - moreButton.getBoundingClientRect().left;
    topNavigationContainer.removeChild(container);

    this.setState({ visibleCategories, moreCategories, moreCategoriesMenuWidth });
  }

  render() {
    const { visibleCategories, moreCategories, moreCategoriesMenuWidth } = this.state;
    const links = getHeaderLinks(visibleCategories, this.props.appData);
    return (
      <nav
        className={classNames(styles.topNavigation, 'blog-header__navigation', 'blog-navigation-container-font')}
        ref={this.setTopNavigationContainer}
      >
        <ul className={styles.container}>
          {links.map(this.renderLink)}
          {this.renderMoreButton(moreCategories, moreCategoriesMenuWidth)}
        </ul>
      </nav>
    );
  }
}

HeaderNavigation.propTypes = {
  t: PropTypes.func.isRequired,
  categories: PropTypes.array.isRequired,
  currentPath: PropTypes.string.isRequired,
  currentPathDecoded: PropTypes.string.isRequired,
  searchInputWidth: PropTypes.number,
  appData: PropTypes.object.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  viewMode: PropTypes.string.isRequired,
  settingsUpdated: PropTypes.number,
};

const mapStateToProps = (state, ownProps, actions, host) => {
  const { page } = getRouteParams(state) || {};
  const currentMatchPathname = getCurrentMatchPathname(state);
  const currentPath = page ? currentMatchPathname.replace(`/page/${page}`, '') : currentMatchPathname;

  return {
    categories: getCategories(state),
    currentPath,
    hostWidth: host.dimensions.width,
    currentPathDecoded: decodeURIComponent(currentPath),
    searchInputWidth: getSearchInputWidth(state),
    appData: getAppData(state),
    viewMode: state.viewMode,
    settingsUpdated: getLastUpdatedDate(state),
  };
};

export default flowRight(connect(mapStateToProps), withAuth, withTranslate)(HeaderNavigation);
