import { flowRight } from 'lodash';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { EXPERIMENT_MOBILE_MENU_COLOR_MATCH_DESKTOP } from '@wix/communities-blog-experiments';
import { ArrowDownIcon } from '../icons/arrow-down-icon';
import withCardBackgroundColor from '../../hoc/with-card-background-color';
import withExperiment from '../../hoc/with-experiment';
import styles from './change-category-trigger.scss';

const ChangeCategoryTrigger = ({
  cardBackgroundColor,
  children,
  title,
  containerBackgroundClass,
  containerFontClass,
  arrowDownIconClass,
  isMobileMenuColorMatchDesktopExperimentEnabled,
  ...props
}) => {
  containerBackgroundClass =
    isMobileMenuColorMatchDesktopExperimentEnabled && containerBackgroundClass
      ? containerBackgroundClass
      : 'blog-default-card-background-color';
  containerFontClass =
    isMobileMenuColorMatchDesktopExperimentEnabled && containerFontClass ? containerFontClass : 'blog-text-color';

  const className = isMobileMenuColorMatchDesktopExperimentEnabled
    ? classNames(styles.container, containerBackgroundClass, containerFontClass, 'categories-button')
    : classNames(
        styles.container,
        'blog-default-card-background-color',
        'blog-text-color',
        'categories-button',
        styles.boxShadow,
      );

  const iconFillClass =
    isMobileMenuColorMatchDesktopExperimentEnabled && arrowDownIconClass ? arrowDownIconClass : 'blog-icon-fill';

  const containerStyle = isMobileMenuColorMatchDesktopExperimentEnabled
    ? null
    : { backgroundColor: cardBackgroundColor };

  return (
    <div className={className} style={containerStyle} {...props}>
      <div className={styles.wrapper}>
        <span className={styles.title}>{title}</span>
        <ArrowDownIcon className={classNames(iconFillClass, styles.icon)} />
        {children}
      </div>
    </div>
  );
};

ChangeCategoryTrigger.propTypes = {
  title: PropTypes.string,
  cardBackgroundColor: PropTypes.string.isRequired,
  children: PropTypes.node,
  containerBackgroundClass: PropTypes.string,
  containerFontClass: PropTypes.string,
  arrowDownIconClass: PropTypes.string,
  isMobileMenuColorMatchDesktopExperimentEnabled: PropTypes.bool,
};

export default flowRight(
  withCardBackgroundColor,
  withExperiment({
    isMobileMenuColorMatchDesktopExperimentEnabled: EXPERIMENT_MOBILE_MENU_COLOR_MATCH_DESKTOP,
  }),
)(ChangeCategoryTrigger);
