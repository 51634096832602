import { get, flowRight } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import CommentHeader from '../../../comments/components/comment-header';
import CommentFooter from '../../../comments/components/comment-footer';
import withFontClassName from '../../../common/hoc/with-font-class-name';
import ContentExcerpt from '../../../common/components/content-excerpt';
import PostExcerpt from '../../../feed-page/components/post-excerpt';
import withIsFeedDesignEnabled from '../../../common/hoc/with-is-feed-design-enabled';
import withFeedBorderWidth from '../../../common/hoc/with-feed-border-width';
import { getFeedColorClassName } from '../../../common/services/layout-config';
import {
  COMMENT_ACTION_SHARE,
  COMMENT_ACTION_REPORT,
  COMMENT_ACTION_DELETE,
} from '../../../comments/constants/comment-actions';
import Link from '../../../common/components/link/internal-link';
import styles from './comment-search-list-item.scss';

const CommentSearchListItem = ({
  comment,
  type,
  query,
  onLikeClick,
  onPostLikeClick,
  borderWidth,
  contentFontClassName,
  contentFontClassNameWithStyle,
  applyFeedDesign,
  getPostClassName,
  layoutType,
}) => {
  const slug = get(comment, 'post.slug');
  if (!slug) {
    return null;
  }
  // const commentLink = `/${slug}#${comment._id}`;
  const commentLink = `/${slug}`;
  const contentFontClass = applyFeedDesign ? getPostClassName('description-font') : contentFontClassName;
  const contentFontClassWithStyle = applyFeedDesign
    ? getPostClassName('description-style-font')
    : contentFontClassNameWithStyle;

  const containerClassName = classNames(
    styles.container,
    styles[type],
    contentFontClass,
    !applyFeedDesign && ['blog-text-color', 'blog-card-background-color', 'blog-card-border-color'],
    getPostClassName(
      getFeedColorClassName(layoutType, 'description-color'),
      getFeedColorClassName(layoutType, 'background-color'),
      'border-color',
    ),
  );

  return (
    <article className={containerClassName} style={{ borderWidth }} data-hook="post-list-item">
      <CommentHeader
        type={type}
        comment={comment}
        visibleActions={[COMMENT_ACTION_SHARE, COMMENT_ACTION_REPORT, COMMENT_ACTION_DELETE]}
      />
      <PostExcerpt post={comment.post} onLikeClick={onPostLikeClick} />
      <Link to={commentLink} renderInSeo={false}>
        <div className={classNames(styles.content, contentFontClassWithStyle)}>
          <ContentExcerpt type={type} content={comment.content} query={query} lineCount={3} maxLength={600} />
        </div>
        <CommentFooter type={type} comment={comment} onLikeClick={onLikeClick} />
      </Link>
    </article>
  );
};

CommentSearchListItem.propTypes = {
  comment: PropTypes.object.isRequired,
  borderWidth: PropTypes.number,
  type: PropTypes.string,
  onLikeClick: PropTypes.func.isRequired,
  onPostLikeClick: PropTypes.func.isRequired,
  contentFontClassName: PropTypes.string.isRequired,
  contentFontClassNameWithStyle: PropTypes.string.isRequired,
  query: PropTypes.string,
  applyFeedDesign: PropTypes.bool.isRequired,
  getPostClassName: PropTypes.func.isRequired,
  layoutType: PropTypes.number,
};

export default flowRight(withFeedBorderWidth, withFontClassName, withIsFeedDesignEnabled)(CommentSearchListItem);
