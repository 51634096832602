import PropTypes from 'prop-types';
import React from 'react';
import { ModalCentered } from '../base/modal-centered';
import ShareLinkForm from './share-link-form';

const ShareLinkModal = ({ closeModal, url }) => (
  <ModalCentered onClose={closeModal}>
    <ShareLinkForm url={url} onCancel={closeModal} />
  </ModalCentered>
);

ShareLinkModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  url: PropTypes.string.isRequired,
  t: PropTypes.func,
};

export default ShareLinkModal;
